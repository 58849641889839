import React from "react";
import "./FooterMain.css";
import coin from "../../assets/coin.png";
import coinRanking from "../../assets/coinRanking.png";
import coinPaprica from "../../assets/coinPaprica.png";
import Dex_Trade from "../../assets/Dex_Trade.png";
import CoinGroup from "../../assets/CoinGroup.png";
import iso from "../../assets/iso.png";
import licensed from "../../assets/licensed.png";
import community from "../../assets/community.png";

const FooterMain = () => {
  return (
    <div>
      <footer>
        <div className="container">
          <div className="footer-top">
            <div className="row">
              <div className="col-xl-6 my-auto">
                <div
                  className="footer-top-1 aos-init"
                  data-aos="fade-right"
                  data-aos-duration={1500}
                >
                  <h4>Find us on</h4>
                  <div className="images">
                  <a href="https://coinranking.com/coin/OH8tScluv+axmint-axm" target="blank"><img src={coinRanking} alt="coinRanking" /></a>
                  <a href="https://coinpaprika.com/coin/axm-axmint/" target="blank"><img src={coinPaprica} alt="coinPaprica" /></a>
                  <a href="https://dex-trade.com/spot/trading/AXMUSDT?interface=classic" target="blank"><img src={Dex_Trade} alt="Dex_Trade" /></a>
                  <a href="https://coinmarketcap.com/currencies/axm-token/" target="blank"><img src={CoinGroup} alt="CoinGroup" /></a>
                  </div>
                </div>
              </div>
              <div className="col-xl-6">
                <div
                  className="footer-top-2 aos-init"
                  data-aos="fade-left"
                  data-aos-duration={1500}
                >
                  <div className="box-1">
                    <img src={coin} alt="" />
                  </div>
                  <div className="box-2">
                    <span>Price</span>
                    <p>$0.44</p>
                  </div>
                  <div className="box-2">
                    <span className="box-flex ">
                      <span>Max Supply:</span>
                      <p>2500000</p>
                    </span>
                    <span className="box-flex ">
                      <span>Circulating Supply:</span>
                      <p>25%</p>
                    </span>
                  </div>
                  <div className="box-2">
                    <span className="box-flex ">
                      <span>Pre-mined:</span>
                      <p>750000</p>
                    </span>
                    <span className="box-flex ">
                      <span>Network:</span>
                      <p>BSC</p>
                    </span>
                  </div>
                </div>
              </div>
              <b className="b1" />
            </div>
            <div className="row">
              <div className="row mt-3">
                {/* Grid column */}
                <div className="col-md-3 col-lg-4 col-xl-3 ">
                  <p className="footerp1">
                    {" "}
                    Ax Mint is the most competitive decentralized platform for
                    earning crypto in the DeFi market.{" "}
                  </p>
                  <a href="https://www.axmint.io/community.html" target="blank" className="footer-anker">
                  <p className="footerp1"> © AXmint Community</p>
                  </a>
                </div>
                <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                  {/* Links */} 
                  <h6 className="text-uppercase footerh6">Products</h6>
                  <p>
                    <a href="https://www.axmint.io/minting.html" target="blank" className="footer-anker">
                      Axmint mining
                    </a>
                  </p>
                  <p>
                    <a href="#" target="blank" className="footer-anker">
                      Ax Blockchain
                    </a>
                  </p>
                  <p>
                    <a href="#" target="blank" className="footer-anker">
                      Dapps &amp; Games
                    </a>
                  </p>
                  <p>
                    <a href="#" target="blank" className="footer-anker">
                      Ax Exchange
                    </a>
                  </p>
                  <p>
                    <a href="#" target="blank" className="footer-anker">
                      Ax Debit card
                    </a>
                  </p>
                  <p>
                    <a href="#" target="blank" className="footer-anker">
                      Ax Payment
                    </a>
                  </p>
                </div>
                <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                  {/* Links */}
                  <h6 className="text-uppercase footerh6">Resource</h6>
                  <p>
                    <a
                      href="https://www.axmint.io/assets/pdf/AxM%20WhitePaper.pdf"
                      className="footer-anker"
                      target="_blank"
                    >
                      Whitepaper
                    </a>
                  </p>
                  <p>
                    <a
                      href="https://www.axmint.io/assets/pdf/AXMachine-Business-Plan-2024.pdf"
                      className="footer-anker"
                      target="_blank"
                    >
                      Business Plan
                    </a>
                  </p>
                  <p>
                    <a
                      href="https://www.axmint.io/"
                      className="footer-anker"
                      target="blank"
                    >
                      AXMint Certificate
                    </a>
                  </p>
                  <p>
                    <a
                      href="https://medium.com/@Axmintdefi"
                      target="_blank"
                      className="footer-anker"
                    >
                      Blog
                    </a>
                  </p>
                  <p>
                    <a
                      href="https://www.axmint.io/assets/pdf/AXM%20Token%20Overview.pdf"
                      className="footer-anker"
                      target="blank"
                    >
                      Overview
                    </a>
                  </p>
                </div>
                <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                  {/* Links */}
                  <h6 className="text-uppercase footerh6">Community</h6>
                  <p>
                    <a
                      href="https://twitter.com/AxmintDefi"
                      target="blank"
                      className="footer-anker"
                    >
                      Twitter
                    </a>
                  </p>
                  <p>
                    <a href="https://t.me/AxMintChat" target="blank" className="footer-anker">
                      Telegram
                    </a>
                  </p>
                  <p>
                    <a
                      href="https://www.reddit.com/user/axmintofficial/"
                      target="blank"
                      className="footer-anker"
                    >
                      Reddit
                    </a>
                  </p>
                  <p>
                    <a
                      href="https://medium.com/@Axmintdefi"
                      target="_blank"
                      className="footer-anker"
                    >
                      Medium
                    </a>
                  </p>
                  <p>
                    <a
                      href="https://discord.com/channels/1184523192149221427/1184523192149221430"
                      target="_blank"
                      className="footer-anker"
                    >
                      Discord
                    </a>
                  </p>
                  <p>
                    <a
                      href="https://www.facebook.com/profile.php?id=61554730316102&mibextid=9R9pXO"
                      target="_blank"
                      className="footer-anker"
                    >
                      Facebook
                    </a>
                  </p>
                  <p>
                    <a
                      href="https://www.youtube.com/@Axmint"
                      target="_blank"
                      className="footer-anker"
                    >
                      Youtube
                    </a>
                  </p>
                  <p>
                    <a
                      href="https://www.linkedin.com/in/axmint/"
                      target="_blank"
                      className="footer-anker"
                    >
                      Linkedin
                    </a>
                  </p>
                </div>
                <div className="col-md-3 col-lg-3 col-xl-3 mx-auto mb-4">
                  <div className="footer-bottom-inner-1">
                    <div className="footer-box">
                      <img src={iso} alt="" />
                      <p>ISO/IEC 27001:2013 &amp; SOC 2 Type 2 Certification</p>
                    </div>
                    <div className="footer-box">
                      <img src={licensed} alt="" />
                      <p>Licensed &amp; Regulated Digital Assets Institution</p>
                    </div>
                    <div className="footer-box">
                      <img src={community} alt="" />
                      <p>Trusted by 6M+ peoplesince 2018</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="row my-4">
              <div className="col-xl-12">
                <p className="footerp1">
                  All or part of the Axmachine Blockchain Services Pvt Ltd, some
                  features thereof, or some Digital Assets, are not available in
                  certain jurisdictions, including where restrictions or
                  limitations may apply, as indicated on the Axmint Platform and
                  in the relevant general terms and conditions.
                </p>
                <p>Copyright 2023 AXM Community. All rights reserved.</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default FooterMain;
