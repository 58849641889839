import Web3 from "web3";
let isItConnected = false;
// let id = 97
// let setId = localStorage.getItem("switch_net")
// console.log("setId", setId);
// if (setId?.toString() == "bsc") {
//   id = 97
// } else {
//   id = 5
// }

const changeNetwork = async ({ id }) => {
  try {
    if (!window.ethereum) throw new Error("No crypto wallet found");
    const web3 = window.web3;
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: web3.utils.toHex(id) }],
    });
    // window.location.reload();
  } catch (err) {
    console.log(err, "not found");
  }
};
const handleNetworkSwitch = async (id) => {
  await changeNetwork({ id });
};
let accounts;
const getAccounts = async () => {
  const web3 = window.web3;
  try {
    accounts = await web3.eth.getAccounts();
    return accounts;
  } catch (error) {
    console.log("Error while fetching acounts: ", error);
    return null;
  }
};
export const disconnectWallet = async () => {
  await window.ethereum.request({
    method: "eth_requestAccounts",
    params: [{ eth_accounts: {} }],
  });
  console.log("disconnect");
};
// export const loadWeb3 = async (id) => {
//   console.log("Calling load web3 with id ==>", id, isItConnected);
//   try {
//     if (window.ethereum) {
//       window.web3 = new Web3(window.ethereum);
//       await window.ethereum.enable();
//       const netId = await window.web3.eth.getChainId();

//       console.log("networkId==>", netId.toString());
//       // await window.web3.eth.getChainId((err, netId) => {
//       if (netId.toString() == id?.toString()) {
//         isItConnected = true;
//       } else {
//         handleNetworkSwitch(id);
//         isItConnected = false;
//       }
//       // switch (netId.toString()) {
//       //   case "97":
//       //     isItConnected = true;
//       //     break;
//       //   default:
//       //     handleNetworkSwitch("bsc");
//       //     isItConnected = false;
//       // }
//       // });
//       if (isItConnected == true) {
//         let accounts = await getAccounts();
//         console.log("accounts", accounts);
//         return accounts[0];
//       } else {
//         let res = "Wrong Network";
//         return res;
//       }
//     } else {
//       let res = "No Wallet";
//       return res;
//     }
//   } catch (error) {
//     let res = "No Wallet";
//     return res;
//   }
// };
export const loadWeb3 = async (id) => {
  console.log("Calling load web3 with id ==>", id);
  try {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
      await window.ethereum.enable();

      // Wait for network switch to complete
      await changeNetwork({ id });

      // Wait for the chainChanged event indicating network switch
      // await new Promise((resolve) => {
      //   window.ethereum.on('chainChanged', () => {
      //     resolve();
      //   });
      // });

      const netId = await window.web3.eth.getChainId();
      console.log("networkId==>", netId.toString());

      if (netId.toString() == id?.toString()) {
        isItConnected = true;
      } else {
        isItConnected = false;
      }

      if (isItConnected) {
        let accounts = await getAccounts();
        console.log("accounts1", accounts);
        return accounts[0];
      } else {
        let res = "Wrong Network...";
        return res;
      }
    } else {
      let res = "No Wallet";
      return res;
    }
  } catch (error) {
    console.log("Error in loadWeb3: ", error);
    let res = "No Wallet";
    return res;
  }
};

