import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import hLogo from "../../assets/hLogo.png";
import "./Header.css";
import { useEffect, useState } from "react";

function Header() {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 100;
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrolled]);
  return (
    <Navbar
      collapseOnSelect
      sticky="top"
      expand="lg"
      className={`MainNav mb-3 sticky-menu ${
        scrolled ? "transcroll" : "bgHdr"
      }`}
    >
      <Container>
        <Navbar.Brand href="/">
          <img className="py-2" src={hLogo} alt="" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link href="https://www.axmint.io/" target="blank">Home</Nav.Link>
            <Nav.Link href="https://www.axmint.io/minting.html" target="blank">Minting</Nav.Link>
            <Nav.Link href="https://www.axmint.io/axmtoken.html" target="blank">AXM Token</Nav.Link>
            <Nav.Link href="https://www.axmint.io/axmlab.html" target="blank">AXM Lab</Nav.Link>
            <Nav.Link href="https://www.axmint.io/community.html" target="blank">Community</Nav.Link>
            <Nav.Link href="https://www.axmint.io/claim-free.html" target="blank">Airdrop</Nav.Link>
            <NavDropdown title="Doc's" id="collapsible-nav-dropdown">
              <NavDropdown.Item href="https://www.axmint.io/assets/pdf/AXMachine-Profile-2024.pdf" target="blank">Profile</NavDropdown.Item>
              <NavDropdown.Item href="https://www.axmint.io/assets/pdf/AXM%20Token%20Overview.pdf" target="blank">
                AXM Token Overview
              </NavDropdown.Item>
              <NavDropdown.Item href="https://www.axmint.io/assets/pdf/Axmint%20Developments%20.pdf" target="blank">
                Developments
              </NavDropdown.Item>
              <NavDropdown.Item href="https://www.axmint.io/assets/pdf/AXMachine-Business-Plan-2024.pdf" target="blank">
                Business Plan
              </NavDropdown.Item>
              <NavDropdown.Item href="https://www.axmint.io/assets/pdf/AxM%20WhitePaper.pdf" target="blank">
                White Paper
              </NavDropdown.Item>
              <NavDropdown.Item href="https://www.axmint.io/assets/pdf/Axmachin%20Milestones%20Reached.pdf" target="blank">
                Milestones Reached
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
          <Nav>
            <Nav.Link href="https://www.axmint.io/signup.html" target="blank">Register</Nav.Link>
            <Nav.Link className="lognBtn" eventKey={2} href="https://www.axmint.io/signin.html" target="blank">
              Login
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
