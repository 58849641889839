import React, { useState, useEffect } from "react";
import "./Hero.css";
import Presale_Guide from "../../assets/Presale_Guide.png";
import ArbitriumSm from "../../assets/ArbitriumSm.png";
import polygonSm from "../../assets/polygonSm.png";
import avalachiSm from "../../assets/avalachiSm.png";
import solanaSm from "../../assets/solanaSm.png";
import supply from "../../assets/supply.png";
import BEP from "../../assets/BEP.png";
import LinkEmg from "../../assets/Link.png";
import pancake from "../../assets/pancake.png";
import POLY from "../../assets/POLY.png";
import AVAX from "../../assets/AVAX.png";
import raydium from "../../assets/raydium.png";
import uniswap from "../../assets/uniswap.png";
import SOLANA from "../../assets/SOLANA.png";
import ARB from "../../assets/ARB.png";
import AXB from "../../assets/AXB.png";
import DTRD from "../../assets/DTRD.png";
import CMC from "../../assets/CMC.png";
import CRnkg from "../../assets/CRnkg.png";
import bitgetLg from "../../assets/bitgetLg.png";
import DClg from "../../assets/DClg.png";
import CClg from "../../assets/CClg.png";
import CBlg from "../../assets/CBlg.png";
import CPPlg from "../../assets/CPPlg.png";
import BNClg from "../../assets/BNClg.png";
import BSClg from "../../assets/BSClg.png";
import CCdx from "../../assets/CCdx.png";
import CGko from "../../assets/CGko.png";
import playGames from "../../assets/playGames.png";
import listed from "../../assets/listed.png";
import stake from "../../assets/stake.png";
import float from "../../assets/float.png";
import target from "../../assets/target.png";
import squares from "../../assets/squares.png";
import coin from "../../assets/coin.png";
import rola from "../../assets/rola.png";
import metamask from "../../assets/metamask.png";
import binance from "../../assets/binance.png";
import coinBase from "../../assets/coinBase.png";
import thunder from "../../assets/thunder.png";
import mathWallet from "../../assets/mathWallet.png";
import TrustWallet from "../../assets/TrustWallet.png";
import walletConnect from "../../assets/walletConnect.png";
import phantomWallet from "../../assets/phantomWallet.png";
import solflare from "../../assets/solflare.png";
import exodus from "../../assets/exodus.png";
import fire from "../../assets/fire.png";
import modalWallet from "../../assets/modalWallet.png";
import robot from "../../assets/robot.png";
import Footer from "../Footer/Footer";
import { Helmet } from "react-helmet";
import { Input, Select, Space } from "antd";
import AOS from "aos";
import "aos/dist/aos.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {
  arbiPresaleAbi,
  arbiPresaleAddress,
  arbiUsdAbi,
  arbiUsdAddress,
  avaxPresaleAbi,
  avaxPresaleAddress,
  avaxUsdAbi,
  avaxUsdAddress,
  polyPresaleAbi,
  polyPresaleAddress,
  polyUsdAbi,
  polyUsdAddress,
  presaleAbi,
  presaleAddress,
  usdAbi,
  usdAddress,
} from "../Api/contractAddress";
import { toast } from "react-toastify";
import { loadWeb3 } from "../Api/connectivity";
import ConnectWallatButton from "../ConnectWalletBtn";
import { useAccount } from "wagmi";
import FooterMain from "../FooterMain/FooterMain";
import { Link } from "react-router-dom";
import light from "../../assets/light.png";
import web3 from "web3";

function MyVerticallyCenteredModal(props) {
  const { connectMetamask } = props;
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
      </Modal.Header>
      <Modal.Body className="toastModal">
        <div className="row">
          <div className="col-md-6">
            <div className="allWallet">
              <h4>Connect Wallet</h4>
              <p className="allWalletPara">
                Start by connecting with one of the wallets below. Be sure to
                store your private keys or seed phrase securely. Neve share them
                with anyone.{" "}
              </p>
              <div className="row modalemgs mt-5">
                <div className="col-4" onClick={() => connectMetamask()}>
                  <img src={metamask} alt="metamask" />
                  <p>Metamask</p>
                </div>
                <div className="col-4">
                  <img src={binance} alt="binance" />
                  <p>Binance Web3</p>
                </div>
                <div className="col-4">
                  <img src={coinBase} alt="coinBase" />
                  <p>CoinBase</p>
                </div>
              </div>
              <div className="row modalemgs mt-2">
                <div className="col-4">
                  <img src={mathWallet} alt="mathWallet" />
                  <p>Math Wallet</p>
                </div>
                <div className="col-4">
                  <img src={TrustWallet} alt="TrustWallet" />
                  <p>Trust Wallet</p>
                </div>
                <div className="col-4">
                  <img src={walletConnect} alt="walletConnect" />
                  <p>Wallet Connect</p>
                </div>
              </div>
              <div className="row modalemgs mt-2">
                <div className="col-4">
                  <img src={phantomWallet} alt="phantomWallet" />
                  <p>Phantom Wallet</p>
                </div>
                <div className="col-4">
                  <img src={solflare} alt="solflare" />
                  <p>Solflare</p>
                </div>
                <div className="col-4">
                  <img src={exodus} alt="exodus" />
                  <p>Exodus</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="scndModalCol">
              <h4>Haven't got a wallet yet</h4>
              <img className="mt-4" src={modalWallet} alt="modalWallet" />
              <img className="robot" src={robot} alt="robot" />
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
const { Option } = Select;

const Hero = () => {
  const [selectedOption, setSelectedOption] = useState("");
  const [userAccount, setUserAccount] = useState("");
  console.log("userAccountuserAccount", userAccount);
  const [calculatedAxm, setCalculatedAxm] = useState(2.5);
  const [enteredUsd, setEnteredUsd] = useState(1);
  const [theusdt, settheusdt] = useState("");
  const [Networkenter, setNetworkenter] = useState("");
  const { address, isConnected } = useAccount();
  // const { Networkenter, setNetworkenter } = useState('');

  const options = ["ARB", "POLY", "AVAX"];
  const [modalShow, setModalShow] = React.useState(false);

  const handleInputChange = async (value) => {
    setSelectedOption(value);
    // setNetworkenter(value);
    console.log("Calling handle input change......A....", value);
    console.log("Selected network:", value);

    const newUserAccount = await connectMetamask(value);

    if (newUserAccount) {
      setModalShow(false);
      setUserAccount(newUserAccount);
    } else {
      console.log("un already connected");
    }

    console.log("ss", value);
    if ("POLY" == value) {
      await setNetworkenter("Polygon");
    } else if ("ARB" == value) {
      await setNetworkenter("Arbitrum");
    } else if ("AVAX" == value) {
      await setNetworkenter("Avalanache");
    }
  };

  const selectAfter = (
    // <Select defaultValue="AVAX" onChange={handleInputChange}>
    <Select
      className="selectMain"
      defaultValue="Select Network"
      onChange={handleInputChange}
      //   onChange={(e) => handleInputChange(e.target.value)}
    >
      <Option value="ARB">
        <span
          style={{ display: "flex", justifyContent: "space-between" }}
          // onClick={() => handleInputChange("ARB")}
        >
          <span>Arbitrum</span>
        </span>
      </Option>
      <Option value="POLY">
        <span
          style={{ display: "flex", justifyContent: "space-between" }}
          // onClick={() => handleInputChange("POLY")}
        >
          <span>Polygon</span>
        </span>
      </Option>
      <Option value="AVAX">
        <span
          style={{ display: "flex", justifyContent: "space-between" }}
          // onClick={() => handleInputChange("AVAX")}
        >
          <span>Avalanche</span>
        </span>
      </Option>
    </Select>
  );

  //   <Option value="SOL">
  //   <span style={{ display: "flex", justifyContent: "space-between" }}>
  //     <span>Solana</span>
  //   </span>
  // </Option>

  const buyAxmBsc = async () => {
    try {
      if (userAccount) {
        const web3 = window.web3;
        // const usdAmount = web3.utils.toWei(enteredUsd.toString(), "ether");
        const usdAmount = enteredUsd * 1000000;
        console.log("USD Amount===", usdAmount, typeof usdAmount, userAccount);
        let preSaleContract = await new web3.eth.Contract(
          presaleAbi,
          presaleAddress
        );

        let token = await new web3.eth.Contract(usdAbi, usdAddress);
        console.log("asa", userAccount);
        console.log("asa1", usdAddress);
        console.log("asa2", presaleAddress);
        const usdBalance = token.methods.balanceOf(userAccount).call();
        if (usdBalance > enteredUsd) {
          await token.methods.approve(presaleAddress, usdAmount).send({
            from: userAccount,
          });

          toast.success("Approved Successfully! 🎉");
          await preSaleContract.methods.Buy(usdAmount).send({
            from: userAccount,
          });
          toast.success("AXM bought sucessfuly");
          console.log("Buying Axm", enteredUsd);
        } else {
          toast.error("Insufficient Balance");
        }
      } else {
        console.log("Wallet not connected");
      }
    } catch (e) {
      console.log("Error while buying AXM", e);
      toast.error("Error while buying AXM");
    }
  };
  const buyAxmAvax = async () => {
    try {
      let aaa = await userAccount;
      console.log("asasasas11111", aaa);
      if (userAccount) {
        if (enteredUsd >= 1) {
          const usdAmount = enteredUsd * 1000000;
          let preSaleContract = await new window.web3.eth.Contract(
            avaxPresaleAbi,
            avaxPresaleAddress
          );

          let token = await new window.web3.eth.Contract(
            avaxUsdAbi,
            avaxUsdAddress
          );
          console.log("asasasas", token);
          let usdBalance = await token.methods.balanceOf(userAccount).call();
          // usdBalance = window.web3.utils.fromWei(
          //   usdBalance.toString(),
          //   "ether"
          // );
          usdBalance = parseFloat(usdBalance) / 1000000;

          console.log("asdfadsf", usdBalance, typeof usdBalance, usdAmount);
          if (parseFloat(usdBalance.toString()) > parseFloat(enteredUsd)) {
            console.log(
              "tttttttttttttt5ttt",
              usdBalance,
              parseFloat(usdBalance),
              enteredUsd,
              parseFloat(usdBalance) > usdAmount
            );
            let approveCall = await token.methods
              .approve(avaxPresaleAddress, usdAmount)
              .send({
                from: userAccount,
              });
            console.log("APPROOVEDDDDDDDDDDDDDDD");
            toast.success("Approved Successfully! 🎉");
            await preSaleContract.methods.Buy(usdAmount).send({
              from: userAccount,
              // value: usdAmount,
            });
            toast.success("AXM bought sucessfuly");
            console.log("Buying Axm", enteredUsd);
          } else {
            console.log("enter");
            toast.error("Insufficient balance");
          }
        } else {
          toast.error("Entered Amount must be atleast 1$");
        }
      } else {
        console.log("Wallet not connected zz");
      }
    } catch (e) {
      console.log("Error while buying AXM", e);
      toast.error("Error while buying AXM Please Check NetWork");
    }
  };
  const buyAxmArbi = async () => {
    console.log("Calling buyAxmArbi");
    try {
      if (userAccount) {
        if (enteredUsd >= 1) {
          // const usdAmount = window.web3.utils.toWei(
          //   enteredUsd.toString(),
          //   "ether"
          // );
          const usdAmount = enteredUsd * 1000000;
          console.log(
            "USD Amount===",
            usdAmount,
            typeof usdAmount,
            userAccount
          );
          console.log("ttttttttttttttttt ====>", web3);
          let preSaleContract = new window.web3.eth.Contract(
            arbiPresaleAbi,
            arbiPresaleAddress
          );
          let token = new window.web3.eth.Contract(arbiUsdAbi, arbiUsdAddress);
          console.log("ttttttttttttttttt ====>");

          let usdBalance = await token.methods.balanceOf(userAccount).call();
          // usdBalance = window.web3.utils.fromWei(
          //   usdBalance.toString(),
          //   "ether"
          // );
          usdBalance = parseFloat(usdBalance) / 1000000;

          if (parseFloat(usdBalance.toString()) > parseFloat(enteredUsd)) {
            console.log("tttttttttttttttttz", usdBalance);
            let approveCall = await token.methods
              .approve(arbiPresaleAddress, usdAmount)
              .send({
                from: userAccount,
              });
            console.log("ADDDD");
            toast.success("Approved Successfully! 🎉");
            await preSaleContract.methods.Buy(usdAmount).send({
              from: userAccount,
            });
            toast.success("AXM bought sucessfuly");
            console.log("Buying Axm", enteredUsd);
          } else {
            toast.error("Insufficient Balance");
          }
        } else {
          toast.error("Entered amount must be atleast 1$");
        }
      } else {
        console.log("Wallet not connected");
      }
    } catch (e) {
      console.log("Error while buying AXM", e);
      toast.error("Error while buying AXM");
    }
  };
  const buyAxmPoly = async () => {
    console.log("kkkkkkasdasdas", selectedOption, userAccount);
    try {
      if (userAccount) {
        // const usdAmount = window.web3.utils.toWei(
        //   enteredUsd.toString(),
        //   "ether"
        // );
        const usdAmount = enteredUsd * 1000000;
        console.log("USD Amount===", usdAmount, typeof usdAmount, userAccount);
        let preSaleContract = await new window.web3.eth.Contract(
          polyPresaleAbi,
          polyPresaleAddress
        );
        let token = await new window.web3.eth.Contract(
          polyUsdAbi,
          polyUsdAddress
        );

        let usdBalance = await token.methods.balanceOf(userAccount).call();
        // usdBalance = window.web3.utils.fromWei(usdBalance.toString(), "ether");
        usdBalance = parseFloat(usdBalance) / 1000000;

        if (parseFloat(usdBalance.toString()) > parseFloat(enteredUsd)) {
          console.log("In balance if");

          let approveCall = await token.methods
            .approve(polyPresaleAddress, usdAmount)
            .send({
              from: userAccount,
              gasLimit: 200000,
              gasPrice: 2000000000000,
            });
          console.log("ADDDD");
          toast.success("Approved Successfully! 🎉");
          await preSaleContract.methods.Buy(usdAmount).send({
            from: userAccount,
            gasLimit: 200000,
            gasPrice: 2000000000000,
            // value: usdAmount,
          });
          toast.success("AXM bought sucessfuly");
          console.log("Buying Axm", enteredUsd);
        } else {
          console.log("In balance else");
          toast.error("Insufficient balance");
        }
        if (enteredUsd >= 1) {
        } else {
          toast.error("Entered amount must be atleast 1$");
        }
      } else {
        console.log("Wallet not connected");
      }
    } catch (e) {
      console.log("Error while buying AXM", e);
      toast.error("Error while buying AXM");
    }
  };
  const buyAxm = async () => {
    console.log("calling buy axm on slecteced blockchain==>", selectedOption);
    if (selectedOption === "BSC") {
      await buyAxmBsc();
    } else if (selectedOption === "AVAX") {
      await buyAxmAvax();
    } else if (selectedOption === "ARB") {
      await buyAxmArbi();
    } else if (selectedOption === "POLY") {
      await buyAxmPoly();
    }
  };

  // const connectMetamask = async (selectedOption) => {
  //   if (selectedOption === "BSC") {
  //     console.log("Selected blockchain1 ==>", selectedOption);
  //     console.log("heroooooo");
  //     let userAcc = await loadWeb3(97);
  //     console.log("heroooooo");
  //     if (userAcc) {
  //       setModalShow(false);
  //       setUserAccount(userAcc);
  //     }
  //   } else if (selectedOption === "ARB") {
  //     console.log("Selected blockchain2 ==>", selectedOption);
  //     let userAcc = await loadWeb3(42161);

  //     if (userAcc) {
  //       setModalShow(false);
  //       setUserAccount(userAcc);
  //     }
  //   } else if (selectedOption === "AVAX") {
  //     console.log("Selected blockchain3 ==>", selectedOption);
  //     let userAcc = await loadWeb3(43114);

  //     if (userAcc) {
  //       setModalShow(false);
  //       setUserAccount(userAcc);
  //     }
  //   } else if (selectedOption === "POLY") {
  //     console.log("Selected blockchain3 ==>", selectedOption);
  //     let userAcc = await loadWeb3(137);

  //     if (userAcc) {
  //       setModalShow(false);
  //       setUserAccount(userAcc);
  //     }
  //   }
  // };

  const connectMetamask = async (selectedOption) => {
    try {
      let userAcc = "";
      settheusdt("USDT");
      switch (selectedOption) {
        case "BSC":
          console.log("Selected blockchain1 ==>", selectedOption);
          userAcc = await loadWeb3(97);
          break;
        case "ARB":
          console.log("Selected blockchain2 ==>", selectedOption);
          userAcc = await loadWeb3(42161);
          break;
        case "AVAX":
          console.log("Selected blockchain3 ==>", selectedOption);
          userAcc = await loadWeb3(43114);
          break;
        case "POLY":
          console.log("Selected blockchain4 ==>", selectedOption);
          userAcc = await loadWeb3(137);
          break;
        default:
          throw new Error("Invalid selected option");
      }

      if (userAcc) {
        setModalShow(false);
        setUserAccount(userAcc);
      } else {
        console.error("Error connecting to MetaMask or unrecognized chain ID");
      }

      return userAcc;
    } catch (error) {
      console.error("Error connecting to MetaMask:", error);
      return null;
    }
  };
  // useEffect(() => {
  //   handleInputChange("AVAX");
  // }, []);

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <div>
      <Helmet>
        <meta
          name="description"
          content="Dynamic sfsdfgfsdgsdfgfdsg sdfgdfgdfgdescription for MyComponent"
        />
      </Helmet>

      <div className="HeroMain">
        <div className="container-fluid">
          <div className="first">
            <div className="row">
              <div className="col-lg-6">
                <img
                  data-aos="fade-right"
                  className=""
                  src={light}
                  alt="light"
                />
                <div data-aos="fade-right" className="intro">
                  <div className="title">
                    <img src={coin} alt="" />
                    <h1 className="AXMMain">
                      AXM for Stable and High Returns in 2024
                      <img src={thunder} alt="" />
                    </h1>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-5 jsdj">
                <div className="ChainBox">
                  <div className="chainHeader">
                    <p>Get In Early In Lowest Price In Presale</p>
                  </div>
                  <h3>$AXM Token Pre Sale</h3>
                  <p>100% sale liquidity</p>
                  <p className="blue">EVM Supporting Blockchain</p>
                  <div className="evmChains">
                    <div className="row">
                      <div className="col-md-4">
                        <button
                          className="arb"
                          onClick={() => handleInputChange("ARB")}
                        >
                          <img
                            src={ArbitriumSm}
                            className="pe-1"
                            alt="ArbitriumSm"
                          />
                          ARBITRUM
                        </button>
                      </div>
                      <div className="col-md-4">
                        <button
                          className="arb"
                          onClick={() => handleInputChange("POLY")}
                        >
                          <img src={polygonSm} className="pe-1" alt="POLYGON" />
                          POLYGON
                        </button>
                      </div>
                      <div className="col-md-4">
                        <button
                          className="arb"
                          onClick={() => handleInputChange("AVAX")}
                        >
                          <img
                            src={avalachiSm}
                            className="pe-1"
                            alt="Avalanche"
                          />
                          Avalanche
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="solanaChain">
                    <p className="blue">Solana Blockchain Token</p>
                    <button className="arb">
                      <img src={solanaSm} className="pe-1" alt="solanaSm" />
                      Solana
                    </button>
                    <h5>1 AXM = 0.4 $</h5>
                  </div>
                  <div className="playReceiveMain">
                    <div className="playReceive">
                      <div className="row">
                        <div className="col-md-6">
                          <h6>Pay With</h6>
                          <Space direction="vertical">
                            <Input
                              style={{
                                padding: "10px 7px !important",
                                fontWeight: "bold",
                              }}
                              type="number"
                              onChange={(e) => {
                                setEnteredUsd(e.target.value);
                                setCalculatedAxm(e.target.value * 2.5);
                              }}
                              addonAfter={selectedAcc}
                              defaultValue={1}
                            />
                          </Space>
                        </div>
                        <div className="col-md-6">
                          <h6>You Receive</h6>
                          <div className="spaceBox">
                            <span>{calculatedAxm}</span>
                            <span>AXM</span>
                          </div>
                          <p className="plySpn">Minimum :1$</p>
                        </div>
                        <div className="connectMAin">
                          
                          <ConnectWallatButton />

                          <MyVerticallyCenteredModal
                            connectMetamask={connectMetamask}
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                          />
                        </div>
                        <div className="connectMAin mt-3">
                          {isConnected && address != "Wrong Network" ? (
                            <Button
                              onClick={() => buyAxm()}
                              className="connectWallet"
                            >
                              Buy AXM
                            </Button>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              <div data-aos="fade-right" className="col-lg-5 jsdj">
                <div className="ChainBox">
                  <h3>$AXM Token Pre Sale on Demand</h3>
                  <p className="fw-bold">
                    Buy AXM on Avalanache, Polygon, Arbitrum, & Solana with
                    respective USDT.
                  </p>
                  <div className="progress">
                    <div
                      className="progress-bar bgBar"
                      role="progressbar"
                      style={{ width: "50%" }}
                      aria-valuenow="50"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                  <img className="firesksk" src={fire} alt="fire" />
                  <div className="playReceiveMain">
                    <div className="playReceive">
                      <div className="row">
                        <div className="row">
                          <div className="col-md-1"></div>
                          <div className="col-md-10">
                            <Space
                              direction="vertical"
                              style={{
                                textAlign: "left",
                                padding: "15px 0",
                                marginTop: "25px",
                              }}
                              className="spaceW"
                            >
                              <div style={{ width: "100%" }}>
                                {" "}
                                {selectAfter}
                              </div>
                            </Space>
                            {/* <img className="squares" src={squares} alt="squares" /> */}
                            <h6 className="pb-3">Pay With</h6>
                          </div>
                          <div className="col-md-1"></div>
                        </div>
                        <div className="row">
                          <div className="col-md-1"></div>
                          <div className="col-md-10 minmmm">
                            <div className="spaceBox">
                              {/* <span>{calculatedAxm}</span> */}
                              {/* <Input> */}
                              <Input
                                type="number"
                                onChange={(e) => {
                                  setEnteredUsd(e.target.value);
                                  setCalculatedAxm(e.target.value * 2.5);
                                }}
                                defaultValue="1"
                                min="1"
                              ></Input>
                              <span>
                                {/* {selectedOption} */}
                                {theusdt}
                                {selectedOption ? (
                                  <img
                                    src={
                                      (selectedOption === "ARB" &&
                                        ArbitriumSm) ||
                                      (selectedOption === "AVAX" &&
                                        avalachiSm) ||
                                      (selectedOption === "POLY" &&
                                        polygonSm) ||
                                      (selectedOption === "SOL" && solanaSm)
                                    }
                                    alt="Arbitrium"
                                  />
                                ) : (
                                  ""
                                )}
                              </span>
                              {/* </Input> */}
                            </div>
                            <p className="plySpn">Minimum :1$</p>
                          </div>
                          <div className="col-md-1"></div>
                          <p>
                            Selected Network is <b>{Networkenter}</b> , You Must
                            Use <b>{Networkenter} Network Based USDT </b>Only to
                            Complete This Transaction
                          </p>
                          <p>You Receive</p>
                          <h1 className="fw-bold">{calculatedAxm} AXM</h1>
                        </div>
                        <div className="connectMAin">
                          <ConnectWallatButton />

                          <MyVerticallyCenteredModal
                            connectMetamask={connectMetamask}
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                          />
                        </div>

                        <div className="connectMAin mt-3">
                          {isConnected && address != "Wrong Network" ? (
                            <Button
                              onClick={() => buyAxm()}
                              className="connectWallet"
                            >
                              Buy AXM
                            </Button>
                          ) : (
                            <></>
                          )}
                        </div>
                        <p className="blue">
                          <Link to="/HowToBuy" target="blank">
                            {" "}
                            <span>How to buy ?</span>
                          </Link>
                          <span>Chat with Us</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-1">
                <img className="rola" src={rola} alt="" />
              </div>
            </div>
          </div>
          <div className="Available">
            <h2 data-aos="zoom-in">We Are available on</h2>
            <div className="container">
              <div className="row AvailableRow">
                <div data-aos="zoom-in" className="col-md-4 resMay">
                  <a
                    href="https://dex-trade.com/spot/trading/AXMUSDT?interface=classic"
                    target="blank"
                  >
                    <img src={DTRD} alt="DTRD" />
                  </a>
                </div>
                <div data-aos="zoom-in" className="col-md-4 resMay">
                  <a
                    href="https://coinmarketcap.com/currencies/axm-token/"
                    target="blank"
                  >
                    <img className="coinMarktBG" src={CMC} alt="CMC" />
                  </a>
                </div>
                <div data-aos="zoom-in" className="col-md-4 resMay">
                  <a
                    href="https://coinranking.com/coin/OH8tScluv+axmint-axm"
                    target="_blank"
                  >
                    <img src={CRnkg} alt="CRnkg" />
                  </a>
                </div>
              </div>
              <div className="row AvailableRow">
                <div data-aos="zoom-in" className="col-md-3 resMay">
                  <a href="https://coincodex.com/crypto/axmint/" target="blank">
                    <img src={CGko} alt="CGko" />
                  </a>
                </div>
                <div data-aos="zoom-in" className="col-md-3 resMay">
                  <a href="https://coincodex.com/crypto/axmint/" target="blank">
                    <img src={CCdx} alt="CCdx" />
                  </a>
                </div>
                <div data-aos="zoom-in" className="col-md-3 resMay">
                  <a
                    href="https://bscscan.com/token/0x391d83d0d44e760c83a23c9385c727045fa59f42"
                    target="blank"
                  >
                    <img src={BSClg} alt="BSClg" />
                  </a>
                </div>
                <div data-aos="zoom-in" className="col-md-3 resMay">
                  <a
                    href="https://www.binance.com/en-IN/price/axm-token"
                    target="blank"
                  >
                    <img src={BNClg} alt="BNClg" />
                  </a>
                </div>
              </div>
              <div className="row AvailableRowT">
                <div data-aos="zoom-in" className="col-md-3 resMay">
                  <a
                    href="https://coinpaprika.com/coin/axm-axmint/"
                    target="blank"
                  >
                    <img src={CPPlg} alt="CPPlg" />
                  </a>
                </div>
                <div data-aos="zoom-in" className="col-md-2 resMay">
                  <a
                    href="https://www.coinbase.com/en-gb/converter/axm/fjd"
                    target="blank"
                  >
                    <img src={CBlg} alt="CBlg" />
                  </a>
                </div>
                <div data-aos="zoom-in" className="col-md-2 resMay">
                  <a href="https://crypto.com/price/axm-token" target="blank">
                    <img src={CClg} alt="CClg" />
                  </a>
                </div>
                <div data-aos="zoom-in" className="col-md-3 resMay">
                  <a
                    href="https://digitalcoinprice.com/coins/axm-token/inr"
                    target="blank"
                  >
                    <img src={DClg} alt="DClg" />
                  </a>
                </div>
                <div data-aos="zoom-in" className="col-md-2 resMay">
                  <a
                    href="https://www.bitget.com/price/axm-token"
                    target="blank"
                  >
                    <img src={bitgetLg} alt="bitgetLg" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="WhyBuy">
            <h2 data-aos="zoom-in-up">Why Buy AXM</h2>
            <p data-aos="zoom-in-up">
              Buy AXM on Avalanache, Polygon, Arbitrum & Solana with respective
              USDT.
            </p>
            <div className="container">
              <div className="row WhyBuyRow">
                <div className="col-md-1"></div>
                <div className="col-md-2 whyBuyEmgHXN">
                  <img
                    data-aos="zoom-in-up"
                    className="img-fluid w-75"
                    src={target}
                    alt="target"
                  />
                  <p data-aos="zoom-in-up" className="text-center">
                    100X by target by 2024
                  </p>
                </div>
                <div className="col-md-2 whyBuyEmgHXN">
                  <img
                    data-aos="zoom-in-up"
                    className="img-fluid w-75"
                    src={float}
                    alt="float"
                  />
                  <p data-aos="zoom-in-up" className="text-center">
                    Supports Crypto to Fiat Payments
                  </p>
                </div>
                <div className="col-md-2 whyBuyEmgHXN">
                  <img
                    data-aos="zoom-in-up"
                    className="img-fluid w-75"
                    src={stake}
                    alt="stake"
                  />
                  <p data-aos="zoom-in-up" className="text-center">
                    Stake AXM for stable & regular APU
                  </p>
                </div>
                <div className="col-md-2 whyBuyEmgHXN">
                  <img
                    data-aos="zoom-in-up"
                    className="img-fluid w-75"
                    src={listed}
                    alt="listed"
                  />
                  <p data-aos="zoom-in-up" className="text-center">
                    Listed on CMC & 4+ Exchanges
                  </p>
                </div>
                <div className="col-md-2 whyBuyEmgHXN">
                  <img
                    data-aos="zoom-in-up"
                    className="img-fluid w-75"
                    src={playGames}
                    alt="playGames"
                  />
                  <p data-aos="zoom-in-up" className="text-center">
                    play Games and buy NFT's using AXM
                  </p>
                </div>
                <div className="col-md-1"></div>
              </div>
            </div>
          </div>
          <div className="supply">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 text_left">
                  <h2 data-aos="zoom-in">AXM Tokenomics</h2>

                  <h2 data-aos="zoom-in" className="axmMulti fw-bold pb-4">
                    AXM Multi-network ecosystem
                  </h2>
                  <p data-aos="zoom-in" className="fw-bold">
                    Axmint is gearing up for its token AXM Presale, distributing
                    70% of tokens on the BEP-20 network with BNB, 10% on AXLab
                    Blockchain with AX, 7%on Solana with SOL, 5% on Avalanche
                    with AVAX, 5% on Polygon with MATIC, and 3% on Arbitrium
                    with ARB. The entire presale proceeds will be channeled into
                    liquidity on the respective network's decentralized exchange
                    platforms.
                  </p>
                </div>
                <div className="col-lg-6 supplyFlx">
                  <img data-aos="zoom-in-up" src={supply} alt="supply" />
                  <div className="supplyboxes">
                    <div data-aos="zoom-in-up" className="blueBoxMain">
                      <div className="blueBox">70%</div>
                      <p>BEP20</p>
                    </div>
                    <div data-aos="zoom-in-up" className="brownBoxMain">
                      <div className="brownBox">3%</div>
                      <p>ARB</p>
                    </div>
                    <div data-aos="zoom-in-up" className="greenBoxMain">
                      <div className="greenBox">5%</div>
                      <p>AVAX</p>
                    </div>
                    <div data-aos="zoom-in-up" className="orangeBoxMain">
                      <div className="orangeBox">5%</div>
                      <p>Poly</p>
                    </div>
                    <div data-aos="zoom-in-up" className="redBoxMain">
                      <div className="redBox">7%</div>
                      <p>Solana</p>
                    </div>
                    <div data-aos="zoom-in-up" className="yellowBoxMain">
                      <div className="yellowBox">10%</div>
                      <p>AXB</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="chainCardsMain">
            <div className="row">
              <div className="col-lg-4">
                <div className="cahinCard">
                  <img src={BEP} alt="BEP" />
                  <h2 className="chainCradHeading">BEP20: 70%</h2>
                  <span className="chainCradSpan">70% Periodic release</span>
                  <p className="chainCradPara">-10K / day</p>
                  <a
                    href="https://bscscan.com/token/0x391d83d0d44e760c83a23c9385c727045fa59f42"
                    target="blank"
                  >
                    <button className="chainCradBtn">
                      <img src={LinkEmg} alt="" />
                    </button>
                  </a>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="cahinCardScnd">
                  <img src={POLY} alt="BEP" />
                  <h2 className="chainCradHeading" data-aos="fade-right">
                    POLY: 5%
                  </h2>
                  <span className="chainCradSpan" data-aos="fade-right">
                    2.5% Sale
                  </span>
                  <p className="chainCradPara" data-aos="fade-right">
                    2.5% Listing on <img src={pancake} alt="pancake" />
                  </p>
                  <a
                    href="https://polygonscan.com/address/0xb7a17A28fB5C33889DC528bfEa32723e0d41Ac1D#code"
                    target="blank"
                  >
                    <button className="chainCradBtn" data-aos="fade-right">
                      <img src={LinkEmg} alt="" />
                    </button>
                  </a>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="cahinCardThrd">
                  <img src={AVAX} alt="AVAX" />
                  <h2 className="chainCradHeading" data-aos="fade-right">
                    AVAX: 5%
                  </h2>
                  <span className="chainCradSpan" data-aos="fade-right">
                    2.5% Sale
                  </span>
                  <p className="chainCradPara" data-aos="fade-right">
                    2.5% Listing on <img src={pancake} alt="pancake" />
                  </p>
                  <a
                    href="https://avascan.info/blockchain/c/address/0xb7a17A28fB5C33889DC528bfEa32723e0d41Ac1D/contract"
                    target="blank"
                  >
                    <button className="chainCradBtn" data-aos="fade-right">
                      <img src={LinkEmg} alt="" />
                    </button>
                  </a>
                </div>
              </div>
            </div>

            <div className="row chainCardScndRow">
              <div className="col-lg-4">
                <div className="cahinCardForth">
                  <img src={SOLANA} alt="SOLANA" />
                  <h2 className="chainCradHeading" data-aos="fade-right">
                    SOL: 7%
                  </h2>
                  <span className="chainCradSpan" data-aos="fade-right">
                    3.5% Sale
                  </span>
                  <p className="chainCradPara" data-aos="fade-right">
                    3.5% Listing on <img src={raydium} alt="raydium" />
                  </p>
                  <a
                    href="https://solscan.io/token/DeEGCWV2H6YQ5qxRku1RWWEkumEXqW8LNGZ2DQxeWvcu"
                    target="blank"
                  >
                    <button className="chainCradBtn" data-aos="fade-right">
                      <img src={LinkEmg} alt="" />
                    </button>
                  </a>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="cahinCardFifth">
                  <img src={ARB} alt="ARB" />
                  <h2 className="chainCradHeading" data-aos="fade-right">
                    ARB: 3%
                  </h2>
                  <span className="chainCradSpan" data-aos="fade-right">
                    1.5% Sale @ 0.25$ / AXM
                  </span>
                  <p className="chainCradPara" data-aos="fade-right">
                    1.5% Listing on <img src={uniswap} alt="uniswap" />
                  </p>
                  <a
                    href="https://arbiscan.io/address/0xb7a17A28fB5C33889DC528bfEa32723e0d41Ac1D#code"
                    target="blank"
                  >
                    <button className="chainCradBtn" data-aos="fade-right">
                      <img src={LinkEmg} alt="" />
                    </button>
                  </a>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="cahinCardThrd">
                  <img src={AXB} alt="AXB" />
                  <h2 className="chainCradHeading" data-aos="fade-right">
                    AXB: 10%
                  </h2>
                  <span className="chainCradSpan" data-aos="fade-right">
                    5% Sale @ 0.25$ / AXM
                  </span>
                  <p className="chainCradPara" data-aos="fade-right">
                    5% Listing
                  </p>
                  <a
                    href="https://axbscan.com/address/0x391D83d0d44E760C83A23c9385C727045FA59f42"
                    target="blank"
                  >
                    <button className="chainCradBtn" data-aos="fade-right">
                      <img src={LinkEmg} alt="" />
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <FooterMain />
        </div>
      </div>
    </div>
  );
};

export default Hero;
