import React from "react";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useAccount, useNetwork } from "wagmi";
import { MdOutlineKeyboardDoubleArrowDown } from "react-icons/md";
import { polygon, arbitrum, avalanche } from "wagmi/chains";
import { PiWalletFill } from "react-icons/pi";

export default function ConnectWalletButton() {
  const { address, isConnected } = useAccount();
  const { open } = useWeb3Modal();
  const { chain } = useNetwork();
  // Define default chains
  const defaultChains = [polygon, arbitrum, avalanche];
  // Extract chain IDs
  const defaultChainIds = defaultChains.map((chain) => chain?.id);

  return (
    <button className="bg-transparent border-0">
      {isConnected ? (
        defaultChainIds.includes(chain?.id) ? (
          <button className="connectWallet" onClick={() => open()}>
            <MdOutlineKeyboardDoubleArrowDown className="fs-5 fw-bold text-white" />
            &nbsp;
            {address.slice(0, 4)}
            ...
            {address.slice(-4)}
          </button>
        ) : (
          <button className="connectWallet" onClick={() => open()}>
            Wrong Network
          </button>
        )
      ) : (
        <button className="connectWallet" onClick={() => open()}>
        <PiWalletFill />
           Connect Wallet to pay
        </button>
      )}
    </button>
  );
}
