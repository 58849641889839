import React, { useEffect } from "react";
import Footer from "../Footer/Footer";
import thunder from "../../assets/thunder.png";
import HowtoBuyMain from "../../assets/HowtoBuyMain.png";
import networks from "../../assets/networks.png";
import step1 from "../../assets/step1.png";
import step2 from "../../assets/step2.png";
import step3 from "../../assets/step3.png";
import step4 from "../../assets/step4.png";
import step5 from "../../assets/step5.png";
import step6 from "../../assets/step6.png";
import step7 from "../../assets/step7.png";
import step8 from "../../assets/step8.png";
import stepThree from "../../assets/stepThree.png";
import coin from "../../assets/coin.png";
import stepSvn from "../../assets/stepSvn.png";
import stepNine from "../../assets/stepNine.png";
import stepT from "../../assets/stepT.png";
import stepFour from "../../assets/stepFour.png";
import stepSix from "../../assets/stepSix.png";
import stepEight from "../../assets/stepEight.png";
import light from "../../assets/light.png";
import "./HowToBuy.css";
import AOS from "aos";
// import "aos/dist/aos.css";

  

const HowToBuy = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <div>
      <div className="HowToBuyMain">
        <div className="container">
          <div className="HowToBuy">
            <div className="row">
              <div className="col-lg-6" data-aos="fade-right">
                <img className="mt-2" src={light} alt="light" />
                <div className="HowToBuyTxt">
                  <div className="pinkBox">
                    <h6>Guide</h6>
                  </div>
                  <h1>
                    How To Buy AXM in Presale{" "}
                    <img src={thunder} alt="thunder" />
                  </h1>
                  <p>
                    The AXM token presale offers an opportunity for investors to
                    purchase tokens at a discounted price before they are listed
                    on exchanges. Here's a step-by-step guide on how to
                    participate in the AXM presale.
                  </p>
                </div>
              </div>
              <div className="col-lg-6" data-aos="fade-left">
                <div className="HowToBuyEmg">
                  <img
                    className="img-fluid w-100 h-100"
                    src={HowtoBuyMain}
                    alt="HowtoBuyMain"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="StepsMain">
            <div className="row">
              <div className="col-lg-6" data-aos="zoom-in-right">
                <div className="step1Main">
                  <div className="step1Text">
                    <div className="greyStepBox">
                      <h6>Step 1</h6>
                    </div>
                    <div className="step1EmgBox">
                      <img src={networks} alt="networks" />
                    </div>
                    <h3>Choose the Blockchain Network</h3>
                    <p>
                      Before participating in the AXM presale, you need to
                      select the blockchain network where you want to buy AXM
                      tokens. The presale is available on multiple blockchain
                      networks, including Polygon, Arbitrum, Avalanche, and
                      Solana.
                    </p>
                  </div>
                  <div className="step1MainEmg">
                    <img src={step1} alt="step1" />
                  </div>
                </div>
              </div>
              <div className="col-lg-6" data-aos="zoom-in-left">
                <div className="step1Main step1MainRes">
                  <div className="step2MainEmg">
                    <img src={step2} alt="step2" />
                  </div>
                  <div className="step1STText">
                    <div className="greyStepBox">
                      <h6>Step 2</h6>
                    </div>
                    <div className="step1EmgBox">
                      <img src={stepT} alt="stepT" />
                    </div>
                    <h3>Acquire USDT</h3>
                    <p>
                      To participate in the AXM presale, you'll need to use USDT
                      on the respective blockchain network. Make sure you have
                      enough USDT in your wallet to cover the amount of AXM
                      tokens you wish to purchase.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6" data-aos="zoom-in-right">
                <div className="step1Main">
                  <div className="step1Text">
                    <div className="greyStepBox">
                      <h6>Step 3</h6>
                    </div>
                    <div className="step1EmgBox">
                      <img src={stepThree} alt="stepThree" />
                    </div>
                    <h3>Access the Presale Platform</h3>
                    <p>
                      Once you have USDT in your wallet you need to access the
                      presale platform specific to the blockchain network you've
                      chosen. Visit the official website or platform where the
                      AXM presale is being conducted.
                    </p>
                  </div>
                  <div className="step2MainEmg">
                    <img src={step3} alt="step3" />
                  </div>
                </div>
              </div>
              <div className="col-lg-6" data-aos="zoom-in-left">
                <div className="step1Main step1MainRes">
                  <div className="step2MainEmg">
                    <img className="step8Res" src={step4} alt="step4" />
                  </div>
                  <div className="step1STText">
                    <div className="greyStepBox">
                      <h6>Step 4</h6>
                    </div>
                    <div className="step1EmgBox">
                      <img src={stepFour} alt="stepFour" />
                    </div>
                    <h3>Connect Your Wallet</h3>
                    <p>
                      Connect your compatible wallet to the presale platform.
                      Ensure that your wallet supports the blockchain network
                      you're using for the presale (e.g., MetaMask for
                      Ethereum-based networks, Sollet for Solana).
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="lineMain">
              <div className="line"></div>
            </div>
            <div className="row">
              <div className="col-lg-6" data-aos="zoom-in-right">
                <div className="step1Main">
                  <div className="step1Text">
                    <div className="greyStepBox">
                      <h6>Step 5</h6>
                    </div>
                    <div className="step1EmgBox">
                      <img src={coin} alt="coin" />
                    </div>
                    <h3>Purchase AXM Tokens</h3>
                    <p>
                      Once your USDT is in your wallet, you can purchase AXM
                      tokens. Specify the amount of AXM you wish to buy and
                      confirm the transaction. The presale price for AXM tokens
                      is $0.4 per token.
                    </p>
                  </div>
                  <div className="step2MainEmg">
                    <img src={step5} alt="step5" />
                  </div>
                </div>
              </div>

              <div className="col-lg-6" data-aos="zoom-in-left">
                <div className="step1Main step1MainRes">
                  <div className="step6MainEmg">
                    <img src={step6} alt="step6" />
                  </div>
                  <div className="step1STText">
                    <div className="greyStepBox">
                      <h6>Step 6</h6>
                    </div>
                    <div className="step1EmgBox">
                      <img src={stepSix} alt="stepSix" />
                    </div>
                    <h3>Confirm and Wait</h3>
                    <p>
                      After placing your order, confirm the transaction details
                      and wait for it to be processed. Depending on network
                      congestion, it may take some time for your transaction to
                      be confirmed on the blockchain.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6" data-aos="zoom-in-right">
                <div className="step1Main">
                  <div className="step1Text">
                    <div className="greyStepBox">
                      <h6>Step 7</h6>
                    </div>
                    <div className="step1EmgBox">
                      <img src={stepSvn} alt="stepSvn" />
                    </div>
                    <h3>Receive AXM Tokens</h3>
                    <p>
                      Once the transaction is confirmed, you'll receive AXM
                      tokens in your connected wallet. Congratulations! You've
                      successfully participated in the AXM presale.
                    </p>
                  </div>
                  <div className="step2MainEmg">
                    <img className="step8Res" src={step7} alt="step7" />
                  </div>
                </div>
              </div>
              <div className="col-lg-6" data-aos="zoom-in-left">
                <div className="step1Main step1MainRes">
                  <div className="step2MainEmg">
                    <img className="step8Res" src={step8} alt="step8" />
                  </div>
                  <div className="step1STText">
                    <div className="greyStepBox">
                      <h6>Step 8</h6>
                    </div>
                    <div className="step1EmgBox">
                      <img src={stepEight} alt="stepEight" />
                    </div>
                    <h3>Monitor Token Distribution</h3>
                    <p>
                      Keep an eye on updates from the AXM team regarding token
                      distribution. Tokens may be distributed immediately after
                      the presale or later, depending on the project's timeline.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="lineMain">
              <div className="line2"></div>
            </div>
            <div className="row my-4">
              <div className="col-lg-6" data-aos="zoom-in-right">
                <div className="step1Main">
                  <div className="step1Text">
                    <div className="greyStepBox">
                      <h6>Step 9</h6>
                    </div>
                    <div className="step1EmgBox">
                      <img src={stepNine} alt="stepNine" />
                    </div>
                    <h3>Prepare for Listing</h3>
                    <p>
                      After purchasing AXM tokens during the presale, you can
                      hold onto them or prepare to trade them once they are
                      listed on exchanges. The listing price for AXM tokens is
                      $2 per token.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6" data-aos="zoom-in-left"></div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default HowToBuy;
