import logo from "./logo.svg";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Hero from "./Components/Hero/Hero";
import Header from "./Components/Header/Header";
import { Routes } from "react-router-dom";
import { Route } from "react-router-dom";
import HowToBuy from "./Components/HowToBuy/HowToBuy";

function App() {
  return (
    <>
      <ToastContainer bodyStyle={{zIndex:10000}}/>
      <Header />
      <Routes>
        <Route path="/" element={<Hero />}></Route>
        <Route path="/HowToBuy" element={<HowToBuy />}></Route>
      </Routes>
    </>
  );
}

export default App;
